import 'chartjs-adapter-luxon'
import { Chart, registerables, Interaction } from 'chart.js';
import CrosshairPlugin from './trace.js';
import Interpolate from './interpolate.js';

// install plugins
Chart.register(...registerables, CrosshairPlugin);
Interaction.modes.interpolate = Interpolate;

class ChartJsInterop {
  constructor() {
    this.ChartMap = new Map();
    this.LegendMap = new Map();
    this.HasRun = false;
  }
  setup(obj){
    if (!obj?.id)
      return;

    const legendMapRef = this.LegendMap.get(obj.id);
    const ctx = document.getElementById(obj.id).getContext('2d');

    const tooltipLine = {
      id: 'tooltipLine',
      beforeDraw: chartReference => {

        if (chartReference.tooltip._active && chartReference.tooltip._active.length) {
          // User is actively hovering over the chart
          this.HasRun = false;
        } else {
          // User has moused off the chart - reset the legend values to most recent values
          const legendMapRef = this.LegendMap.get(this.LegendMap.keys().next().value);
          if (legendMapRef && !this.HasRun) {
            updateLegend(chartReference.config.data.datasets, legendMapRef);
            this.HasRun = true;
          }
        }
      }
    };

    // Apply the tooltip styling/interactions
    const tooltipPlugin = Chart.registry.getPlugin('tooltip');
    tooltipPlugin.positioners.myCustomPositioner = function (elements, position) {
      if (!elements.length) {
        return false;
      }
      return {
        x: position.x,
        y: elements[0].element.tooltipPosition().y
      };
    };

    let config = createConfig(obj.crosshairColor, obj.crosshairPattern, obj.timeUnit, obj.displayScales);
    config.options.plugins.tooltip = getChartTooltipObject(legendMapRef);
    config.plugins = [tooltipLine];

    //Apply localized currency formatting to the vertical label axis (Ex $5,000)
    config.options.scales.y.ticks.callback = function (value, index, values) {
      return getNumberFormat(legendMapRef, value, 0);
    }

    let chartReference = new Chart(ctx, config);
    this.ChartMap.set(obj.id, chartReference);

    //Update the chart
    chartReference.update();
  }

  updateChartOptions(obj) {
    if (!obj?.id)
      return;

    let chartReference = this.ChartMap.get(obj.id);
    const legendMapRef = this.LegendMap.get(obj.id);

    if (!chartReference)
      return;

    if (obj.data) {
      chartReference.data.datasets = obj.data.datasets;
    }

    if(obj.labels){
      chartReference.tooltipLabels = obj.labels;
    }

    if (obj.timeUnit && chartReference.options.scales.x.time.unit !== obj.timeUnit)
      chartReference.options.scales.x.time.unit = obj.timeUnit;

    if(!isNaN(obj.minX))
      chartReference.options.scales.x.min = obj.minX;

    if(!isNaN(obj.maxX))
      chartReference.options.scales.x.max = obj.maxX;

    if(chartReference.data && chartReference.data.datasets && chartReference.data.datasets.length > 0)
      updateLegend(chartReference.data.datasets, legendMapRef);

    chartReference.update();

    // Apply Gradient backgroundColor to Datasets
    for (let i= 0; i < chartReference.data.datasets.length; i++){
      if (chartReference.data.datasets[i].backgroundColor === "gradient")
        chartReference.data.datasets[i].backgroundColor = getGradientFill(obj.id, chartReference.data.datasets[i].borderColor, chartReference, i);
    }

    chartReference.update();
  }

  //sets a map object of values to be referenced when updating the chart/legend values.
  registerDotNetInstance(obj) {
    if (!obj?.id)
      return;

    //construct dotNet/legend update object
    const legendMapObj = {
      legendUpdateMethod: obj.legendUpdateMethodName,
      dotNetObject: obj.dotNetObj,
      legendFormatStringMethodName: obj.legendFormatStringMethodName
    }

    this.LegendMap.set(obj.id, legendMapObj);
  }

  //cleanup and remove items from Maps/ reset hasRun flag
  cleanup(obj) {
    if (!obj?.id)
      return;

    this.LegendMap.delete(obj.id);
    this.ChartMap.delete(obj.id);
    this.HasRun = false;
  }
}

// Get English ordinal suffix for the day of the month
function getOrdinalSuffix(day) {
  if (day > 3 && day < 21)
    return 'th';

  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

function getGradientFill(objId, baseColor, chartRef, datasetIndex){
  // Constants
  const alpha = 0.2;
  const minPercentage = 0.15;

  // Extracting y-axis scale and range values
  const yScale = chartRef._metasets[datasetIndex].yScale;
  const yMinValue = yScale._range.min;
  const yMaxValue = yScale._range.max;
  const chartMinValue = yScale.min;
  const chartMaxValue = yScale.max;
  const chartBottom = chartRef.chartArea.bottom;
  const chartTop = chartRef.chartArea.top;
  const chartPixelRange = chartBottom - chartTop;
  const relativeYMinInPixels = chartTop + (yMinValue - chartMinValue) / (chartMaxValue - chartMinValue) * chartPixelRange;
  const relativeYMaxInPixels = chartTop + (yMaxValue - chartMinValue) / (chartMaxValue - chartMinValue) * chartPixelRange;

  // Getting 2D rendering context
  const ctx = document.getElementById(objId).getContext('2d');

  // Creating linear gradient
  const gradient = ctx.createLinearGradient(0, relativeYMaxInPixels, 0, relativeYMinInPixels);

  // Extracting RGB color components
  const rgb = hexToRgb(baseColor);

  // Function to generate RGBA color string
  function getColor(a) { return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${a})`; }

  // Setting color stops based on y-axis range
  if (yMinValue >= 0) {
    gradient.addColorStop(minPercentage, getColor(0));
    gradient.addColorStop(1, getColor(alpha));
  } else if (yMaxValue <= 0) {
    gradient.addColorStop(0, getColor(alpha));
    gradient.addColorStop(1-minPercentage, getColor(0));
  } else {
    // Calculating zero percentage and setting color stops accordingly
    const zeroPercentage = Math.abs(yMinValue) / (Math.abs(yMinValue) + yMaxValue);
    const lower = Math.max(zeroPercentage - minPercentage, minPercentage);
    const upper = Math.min(zeroPercentage + minPercentage, 1 - minPercentage);

    gradient.addColorStop(0, getColor(alpha));
    gradient.addColorStop(lower, getColor(0));
    gradient.addColorStop(upper, getColor(0));
    gradient.addColorStop(1, getColor(alpha));
  }
  return gradient;
}

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

// This renders the vertical tooltip line on hover and any other tooltip values. For now, we're just showing the vertical line (both title and labels are returning an empty string).
function getChartTooltipObject(legendMapRef) {
  return {
    // Hide the tooltip for now
    display: true,
    borderColor: 'rgb(244, 245, 247)',
    borderWidth: 2,
    backgroundColor: 'rgb(255, 255, 255)',
    titleColor: 'rgb(107, 119, 140)',
    titleFont: {size: 12, weight: 400, lineHeight: 1.2},
    titleMarginBottom: 12,
    bodyColor: 'rgba(9, 30, 66)',
    bodyFont: {size: 12, weight: 400, lineHeight: 1.2},
    bodySpacing: 10,
    padding: 16,
    caretPadding: 10,
    boxHeight: 6,
    boxWidth: 6,
    boxPadding: 4,
    usePointStyle: true,
    // The tooltip is hidden so we don't need animation
    animation: false,
    mode: "interpolate",
    intersect: false,
    position: "average",
    callbacks: {
      title: function (a, d) {
        const timeUnit = a[0]?.chart?.scales?.x?._unit ?? 'week';
        const date = new Date(a[0].element.x);
        const time = date.toLocaleString('en-US', {hour: 'numeric', minute: 'numeric', hour12: true});
        const month = date.toLocaleString('en-US', {month: 'long'});
        const day = date.getDate();
        const year = date.getFullYear();
        return `${month} ${day}${getOrdinalSuffix(day)}, ${year}${(timeUnit === 'day') ? ' - '+time : ''}`;
      },
      labelColor: function (context) {
        return {
          borderColor: context.dataset.borderColor,
          backgroundColor: context.dataset.borderColor,
          borderRadius: 5,
        };
      },
      label: (d) => {
        if (legendMapRef) {
          const dataset = d.chart.data.datasets[d.datasetIndex];
          const startValue = Number(dataset.data[0].y);
          const endValue = Number(d.element.y);
          const percentChange = (endValue - startValue) / startValue;

          legendMapRef.dotNetObject.invokeMethodAsync(legendMapRef.legendUpdateMethod, dataset.id, endValue, percentChange, true);
        }
        return (
          // This converts Y value into DatasetLabel + "   " + $Y with 2 decimals
          // Dataset Label (Ex. Realized Gains)
          d.chart.tooltipLabels[d.datasetIndex]
          + '   '
          //Apply localized currency formatting to the vertical label axis (Ex $5,000)
          + getNumberFormat(legendMapRef, d.element.y)
        );
      }
    }
  }
}

function updateLegend(datasets, legendMapRef) {
  if (!datasets || !legendMapRef) return;

  datasets
    .filter(dataset => Array.isArray(dataset.data) && dataset.data.length !== 0)
    .forEach(dataset => {
      const startValue =  Number(dataset.data[0].y);
      const endValue = Number(dataset.data[dataset.data.length - 1].y);
      const percentChange = (endValue - startValue) / startValue;

      legendMapRef.dotNetObject.invokeMethodAsync(legendMapRef.legendUpdateMethod, dataset.id, endValue, percentChange, false);
    });
}

function createConfig(crosshairColor = '#505F79', crosshairPattern = [0, 100000000], timeUnit = 'month', displayScales = true){
  return {
    data: [],
    options: {
      animation: false,
      elements: {
        line: {
          borderWidth: 1.75
        },
        point: {
          radius: 0
        }
      },
      interaction: {
        intersect: false
      },
      layout: {
        padding: {
          left: 0,
          right: 0
        }
      },
      maintainAspectRatio: false,
      normalized: true,
      parsing: false,
      plugins: {
        crosshair: {
          line: {
            color: crosshairColor,
            dashPattern: crosshairPattern,
            width: 1
          }
        },
        decimation: {
          algorithm: 'min-max',
          enabled: true,
        },
        legend: {
          display: false
        },
        title: {
          display: false,
        }
      },
      responsive: true,
      scales: {
        x: {
          axis: 'x',
          border: {
            display: false
          },
          display: displayScales,
          grid: {
            display: false,
            drawBorder: false
          },
          ticks: {
            autoSkip: true,
            autoSkipPadding: 40,
            color: '#A7B2C2',
            font: {
              family: 'Inter',
              size: 10,
              weight: 700
            },
            major: {
              enabled: false
            },
            maxTicksLimit: null,
            stepSize: 1
          },
          time: {
            unit: timeUnit
          },
          type: 'time',
        },
        y: {
          border: {
            display: false
          },
          display: displayScales,
          grid: {
            display: true,
            drawBorder: false
          },
          ticks: {
            autoSkip: false,
            autoSkipPadding: 0,
            color: '#808FA3',
            font: {
              family: 'Inter',
              size: 10,
              weight: 500
            },
            major: {
              enabled: false
            },
            maxTicksLimit: 5,
            stepSize: 10000
          },
          title: {
            display: false
          }
        },
        y1: {
          border: {
            display: false
          },
          display: false,
          grid: {
            display: false,
            drawBorder: false
          },
          title: {
            display: false
          }
        }
      },
    },
    plugins: [],
    type: 'scatter'
  };
}

// Gets formatted currency from C#
function getNumberFormat(legendMapRef, value, decimals = 2) {
  return legendMapRef.dotNetObject.invokeMethod(legendMapRef.legendFormatStringMethodName, value, decimals);
}

const chartJsInterop = new ChartJsInterop();

window.ChartJsInterop = chartJsInterop;

if (typeof globalThis === "object")
  globalThis.ChartJsInterop = chartJsInterop;
